import Vue from "vue";
import Router from 'vue-router'
Vue.use(Router)
const router = new Router({
  routes:[
    {
      path:'',
      name:'Home',
      component:()=>import("@/view/home/index.vue")
    }
  ]
})
export  default router;
