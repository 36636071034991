import Vue from 'vue'
import App from './App.vue'
import {BtChart} from 'bt-design'

import router from "./router/index.js"

import {Tabs,TabPane,Table,TableColumn,Row,Col,Dropdown,DropdownItem,DropdownMenu,Form,FormItem,Select,Option,Input,Button,Loading} from 'element-ui'
import './styles/element-variables.scss'
Vue.use(BtChart)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Row)
Vue.use(Col)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Input)
Vue.use(Button)
Vue.use(Loading)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
